import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Photo from '../components/images/photo';

const Index = ({ data: { banner } }) => (
  <Layout>
    <SEO title="Home" ogTitle="Danielle Abril - Writer" />

    <section id="banner">
      <div className="inner">
        <p>Writer. Editor.</p>
        <h1>Lover of stories.</h1>
      </div>

      <div className="banner-background">
        <BackgroundImage
          fluid={banner.childImageSharp.fluid}
          preserveStackingContext={true}
          style={{ position: 'inherit' }}
        />
      </div>
    </section>

    <section id="bio" className="wrapper style2">
      <div className="inner">
        <section className="spotlights">
          <article>
            <header className="major">
              <h2>About me</h2>
            </header>

            <div className="image left photo">
              <Photo />
            </div>

            <p>
              For more than 10 years, I have been dedicated to telling the
              stories of my communities, holding the powerful accountable, and
              giving a voice to the unheard. I currently am a technology writer
              at Fortune, responsible for covering some of Silicon Valley's
              largest tech companies, including Facebook and Google. Prior to
              that, I worked for three of Dallas' most prominent publications.
              I'm conversational in Spanish, have news photography experience,
              and am a quick learner and an avid Tweeter. I also am well-versed
              in Latin dancing and queso. As a passionate and driven journalist,
              I believe there will always be the need for storytellers and
              watchdogs.
            </p>
          </article>
        </section>
      </div>
    </section>

    <section className="wrapper style1 special">
      <div className="inner"></div>
    </section>
  </Layout>
);

export default Index;

export const pageQuery = graphql`
  query {
    banner: file(relativePath: { eq: "banner.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1848) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
